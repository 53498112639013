'use strict'

clinicSlide = new Swiper('.p-clinic-summary__slide', {
  speed: 2000
  allowTouchMove: false
  autoplay: {
    delay: 7000,
    disableOnInteraction: false,
  },
  loop: true,
  effect: 'fade'
})